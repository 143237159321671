import Vue from "vue"
import Vuex from "vuex"
import modules from "./modules"
import { getField, updateField } from "vuex-map-fields"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {
    getField
  },
  mutations: {
    updateField
  },
  actions: {},
  modules: modules
})
