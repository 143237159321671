<template lang="pug">
  v-app
    AppBar(@goTo="goTo")
    NavigationDrawer(@goTo="goTo")
    v-main
      v-slide-y-transition(hide-on-leave)
        router-view
</template>

<script>
import AppBar from "./components/AppBar"
import NavigationDrawer from "./components/NavigationDrawer"

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Go Beyond",
    // all titles will be injected into this template
    titleTemplate: "%s | Beyond Company",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "Soluções que impactam sua empresa." },
      {
        name: "keywords",
        content:
          "tecnologia, desenvolvimento, design, inteligência artificial, arquitetura de cloud, migração e personalização do G Suite"
      },
      { name: "robots", content: "index, follow" },
      // OpenGraph data (Most widely used)
      { property: "og:title", content: "Home ← Beyond Company" },
      { property: "og:site_name", content: "Beyond Company" },
      // The list of types is available here: http://ogp.me/#types
      { property: "og:type", content: "website" },
      // Should the the same as your canonical link, see below.
      {
        property: "og:url",
        content: "https://beyondcompany.com.br"
      },
      {
        property: "og:image",
        content: "https://beyondcompany.com.br/logo.png"
      },
      // Often the same as your meta description, but not always.
      {
        property: "og:description",
        content: "Soluções que impactam sua empresa."
      },

      // Twitter card
      { name: "twitter:card", content: "summary" },
      {
        name: "twitter:site",
        content: "https://beyondcompany.com.br"
      },
      { name: "twitter:title", content: "Home ← Beyond Company" },
      {
        name: "twitter:description",
        content: "Soluções que impactam sua empresa."
      },
      // Your twitter handle, if you have one.
      // { name: "twitter:creator", content: "@beyondcompany" },
      {
        name: "twitter:image:src",
        content: "https://beyondcompany.com.br/logo.png"
      },

      // Google / Schema.org markup:
      { itemprop: "name", content: "Home ← Beyond Company" },
      {
        itemprop: "description",
        content: "Soluções que impactam sua empresa."
      },
      {
        itemprop: "image",
        content: "https://beyondcompany.com.br/logo.png"
      }
    ],
    link: [{ rel: "canonical", href: "https://beyondcompany.com.br" }]
  },
  components: {
    // AppBar: () => import("./components/AppBar"),
    // NavigationDrawer: () => import("./components/NavigationDrawer")
    AppBar,
    NavigationDrawer
  },
  methods: {
    async goTo(target) {
      const options = {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic"
      }

      // if not on main page, redirect before scrolling
      if (this.$route.path !== "/") await this.$router.push("/")

      // offset background negative margin for this section
      if (target === "#products-top") options.offset = -60

      this.$vuetify.goTo(target, options)
    }
  }
}
</script>

<style lang="sass">
.main-container
  overflow-x: hidden

.beyond-title
  font-weight: bold
  font-size: 36px
  line-height: 1

  color: var(--v-beyondTitle-base)

  @media (min-width: 600px)
    font-size: 48px

.beyond-subtitle
  font-weight: 300
  font-size: 14px
  line-height: 1

  color: var(--v-beyondText-base)

  @media (min-width: 600px)
    font-size: 24px

.beyond-text
  font-weight: 300
  font-size: 12px
  line-height: 1

  color: var(--v-beyondText-base)

  @media (min-width: 600px)
    font-size: 19px

.beyond-info
  font-weight: 300
  font-size: 14px
  line-height: 107%

  color: var(--v-beyondText-base)

.VueCarousel-navigation
  &-button
    padding: 0 !important
    width: 28px !important
    height: 50px !important
    transition: transform .1s cubic-bezier(0.4, 0.0, 0.2, 1) !important


  &-next
    background: url(./assets/chevron-right.svg) !important
    &:hover
      transform: translateY(-50%) translateX(100%) scale(1.2) !important

  &-prev
    background: url(./assets/chevron-left.svg) !important
    &:hover
      transform: translateY(-50%) translateX(-100%) scale(1.2) !important
</style>
