import Vue from "vue"
import Vuetify from "vuetify/lib"
import pt from "vuetify/es5/locale/pt"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#A64BAA",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        navBtnText: "#A64BAA",
        beyondTitle: "#401580",
        beyondText: "#363636",
        beyondGreen: "#49d880",
        beyondOrange: "#F8B73F",
        beyondPurple: "#6F3ABE",
        beyondPurpleBtn: "#A64BAA",
        beyondYellow: "#FFF35C",
        beyondWhiteBg: "#EDEDED",
        formSendBtn: "#47368C"
      },
      dark: {
        primary: "#A64BAA",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        navBtnText: "#A64BAA",
        beyondTitle: "#401580",
        beyondText: "#363636",
        beyondGreen: "#49d880",
        beyondOrange: "#F8B73F",
        beyondPurple: "#6F3ABE",
        beyondPurpleBtn: "#A64BAA",
        beyondYellow: "#FFF35C",
        beyondWhiteBg: "#EDEDED",
        formSendBtn: "#47368C"
      }
    }
  },
  lang: {
    locales: { pt },
    current: "pt"
  }
})
