<template lang="pug">
  v-footer#footer-section.bg8
    v-container.white--text.pa-4.pa-sm-12(style="max-width: 1366px;")
      v-row.my-lg-12(align='center' justify='center')
        v-col(cols='auto')
          v-row
            v-col(cols='auto')
              div.box-title produtos
              div.box-text(v-for='(item, index) in produtos' :key='index')
                a.box-anchor(:href='item.link') {{item.title}}
            v-col(cols='auto')
              div.box-title serviços
              div.box-text(v-for='(item, index) in servicos' :key='index')
                a.box-anchor(:href='item.link') {{item.title}}
            v-col(cols='auto')
              div.box-title a beyond co.
              div.box-text(v-for='(item, index) in beyondcompany' :key='index')
                a.box-anchor(:href='item.link') {{item.title}}
            v-col(cols='auto')
              a#contact-btn.box-title.box-anchor(@click="goToContact") contato
        v-divider.white.mx-14(v-if='$vuetify.breakpoint.lgAndUp' vertical inset)
        //- div.spacer-line.mx-12(v-if='$vuetify.breakpoint.lgAndUp')
        v-col(cols='auto')
          v-row(align='center' :justify="$vuetify.breakpoint.xsOnly ? 'start' : 'center'")
            v-col(cols='auto')
              v-img(src='../assets/logo-white.svg' width="172" height="29" contain eager)
            v-col.ml-sm-8(cols='auto')
              div.beyond-text.font-weight-bold Beyond Co.
              div.beyond-info Rua General Polidoro, 714 - Sala 103/105
              div.beyond-info Várzea, Recife/PE - CEP 50740-050
              div.beyond-info CNPJ 32.465.035/0001-92
              div.mt-8
                v-btn(icon dark large aria-label="Linkedin page" @click="openUrl('https://www.linkedin.com/company/beyond-cloud/')")
                  v-img(src="../assets/icons/footer-linkedin.svg" width="36" height="36" alt="Linkedin Icon" contain)
                v-btn(icon dark large aria-label="Instagram page" @click="openUrl('https://www.instagram.com/beyond.coo/')")
                  v-img(src="../assets/icons/footer-instagram.svg" width="36" height="36" alt="Instagram Icon" contain)
                v-btn(icon dark large aria-label="Facebook page")
                  v-img(src="../assets/icons/footer-facebook.svg" width="36" height="36" alt="Facebook Icon" contain)
              div.beyond-info.mt-1 +55 81 3204-7240
              div.beyond-info contato@beyondcompany.com.br
              div.beyond-info beyondcompany.com.br
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      produtos: [
        {
          title: "minha sala",
          link: "https://www.minhasala.app"
        },
        {
          title: "quoti",
          link: "https://www.quoti.com.br"
        },
        {
          title: "jusapi",
          link: "https://www.jusapi.com"
        }
      ],
      servicos: [
        {
          title: "design",
          link: "#"
        },
        {
          title: "consultoria",
          link: "#"
        },
        {
          title: "desenvolvimento",
          link: "#"
        }
      ],
      beyondcompany: [
        {
          title: "sobre",
          link: "#"
        },
        {
          title: "nosso time",
          link: "#"
        },
        {
          title: "parceiros",
          link: "#"
        }
      ]
    }
  },
  methods: {
    openUrl(url) {
      window.open(url, "_blank")
    },
    goToContact() {
      this.$router.push("/contact")
    }
  }
}
</script>

<style lang="sass" scoped>
.bg8
  background-color: #2E2E2E !important

.beyond-text,.beyond-info
  color: #F7F7F7

// .spacer-line
//   height: 201px
//   width: 1px
//   background-color: #ffffff

.box-title
  font-size: 16px
  font-weight: bold
  line-height: 300%

.box-text
  font-size: 14px
  font-weight: 300
  line-height: 300%

.box-anchor
  text-decoration: none
  color: #F7F7F7
</style>
