<template lang="pug">
  div#header-section.bg1
    v-container(:fluid="$vuetify.breakpoint.mdAndDown" :class="{ 'pa-0': $vuetify.breakpoint.mdAndDown }" style="max-width: 1366px;")
      v-row.top-row(align="start" justify="start" no-gutters)
        v-col.px-4.pl-sm-4.ml-md-12(cols="auto")
          v-container
            h1.pb-4.beyond-title Soluções que <strong><i>impactam</i></strong> sua empresa.
            p.beyond-subtitle Nós utilizamos desenvolvimento ágil e tecnologia de ponta ao criar soluções para nossos clientes.
        //- v-img.green-triangle(v-if="$vuetify.breakpoint.xsOnly" src="../assets/green-triangle.svg" contain eager)
        //- v-img.purple-circle(v-if="$vuetify.breakpoint.xsOnly" src="../assets/purple-circle.svg" contain eager)
        v-img.desenho1(v-if="$vuetify.breakpoint.smAndUp" src="../assets/desenho1.svg" contain eager)
</template>

<script>
export default {
  name: "Header",
  components: {}
}
</script>

<style lang="sass" scoped>
#header-section
  padding-bottom: 60px

  .top-row
    position: relative
    padding-top: 82px
    padding-bottom: 90px

    @media (min-width: 600px)
      padding-top: 140px
      padding-bottom: 260px

  .beyond-title
    max-width: 250px

    @media (min-width: 600px)
      max-width: 585px

  .beyond-subtitle
    max-width: 232px

    @media (min-width: 600px)
      max-width: 470px

.bg1
  z-index: 21
  position: relative
  background: url(../assets/bg1.svg)
  background-repeat: no-repeat
  background-size: 100% 100%

.desenho1
  position: absolute
  width: 60%
  max-width: 845px
  transition: transform .3s cubic-bezier(0.4, 0.0, 0.2, 1)

  &:hover
    transform: scale(1.1)

  @media (min-width: 600px)
    right: -20px
    top: 390px

  @media (min-width: 650px)
    right: -80px
    top: 360px

  @media (min-width: 700px)
    right: -90px
    top: 280px

  @media (min-width: 800px)
    right: -80px
    top: 220px

  @media (min-width: 960px)
    right: -80px
    top: 150px

  @media (min-width: 1120px)
    right: -80px
    top: 110px

  @media (min-width: 1264px)
    right: -70px
    top: 0

// .green-triangle
//   position: absolute
//   width: 60vw
//   top: 50%
//   left: -80px
//   transform: translate(0, 50%) rotate(45deg)

//   @media (min-width: 600px)
//     left: -100px
//     transform: scale(1)

// .purple-circle
//   position: absolute
//   width: 60vw
//   top: 50%
//   right: -80px
//   transform: translate(0, 50%)

//   @media (min-width: 600px)
//     transform: scale(1)
</style>
