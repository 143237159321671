<template lang="pug">
  div#partners-top
    v-img.xs-drawing(v-if="$vuetify.breakpoint.xsOnly" src="../assets/partners-top-xs-drawing.svg" contain eager)
    v-img.xs-drawing2(v-if="$vuetify.breakpoint.xsOnly" src="../assets/partners-top-xs-drawing-2.svg" contain eager)
    v-container(style="max-width: 1366px; position: relative;" fill-height)
      v-img.desenho3(v-if="$vuetify.breakpoint.smAndUp" src="../assets/desenho3.svg" contain eager)
      v-row(justify="center")
        v-col.px-6(cols="auto" style="z-index: 18;")
          span.beyond-title Nossos parceiros
          p.pt-6.beyond-subtitle Empresas que junto à Beyond são impactadas por soluções universais em nuvem que promovem comodidade e eficiência para seus negócios.
          v-btn.mt-6.text-none(@click="$router.push('/contact')" :large="$vuetify.breakpoint.smAndDown" :x-large="$vuetify.breakpoint.mdAndUp" rounded depressed color="beyondTitle" dark) Clique e comece a fazer parte!
</template>

<script>
export default {
  name: "PartnersTop"
}
</script>

<style lang="sass" scoped>
#partners-top
  position: relative
  margin-top: -110px
  padding-top: 200px
  padding-bottom: 100px
  // margin-bottom: -140px
  background-color: var(--v-beyondOrange-base)

  .col
    // max-width: 90%

  .desenho3
    z-index: 18
    position: absolute
    width: 40%
    max-width: 415px
    bottom: -50px
    left: -43px
    transition: transform .3s cubic-bezier(0.4, 0.0, 0.2, 1)

    &:hover
      transform: scale(1.1)

  .xs-drawing
    position: absolute
    width: 250px
    bottom: 0
    right: 0
    transform: translate(50%, 50%) rotate(-17deg)

  .xs-drawing2
    z-index: 18
    position: absolute
    width: 220px
    top: 0
    left: 0
    transform: translate(-65%, -15%) rotate(-110deg)


@media (min-width: 600px)
  #partners-top
    .col
      max-width: 60%
      margin-left: 30%

    .desenho3
      bottom: -150px

@media (min-width: 960px)
  #partners-top
    .col
      max-width: 567px
      margin-left: 15%

    .desenho3
      width: 30%

@media (min-width: 1264px)
  #partners-top
    .desenho3
      bottom: -250px
</style>
