<template lang="pug">
  v-container.main-container.pa-0(fluid)
    v-row(no-gutters)
      v-col
        Header()
        Multidisciplinar()
        ProductsTop()
        ProductsSlider()
        PartnersTop()
        PartnersSlider()
        CallToAction()
        Footer()

</template>

<script>
import Header from "../components/Header"
import Multidisciplinar from "../components/Multidisciplinar"
import ProductsTop from "../components/ProductsTop"
import ProductsSlider from "../components/ProductsSlider"
import PartnersTop from "../components/PartnersTop"
import PartnersSlider from "../components/PartnersSlider"
import CallToAction from "../components/CallToAction"
import Footer from "../components/Footer"

export default {
  name: "Home",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Home"
  },
  components: {
    // Header: () => import("../components/Header"),
    // Multidisciplinar: () => import("../components/Multidisciplinar"),
    // ProductsTop: () => import("../components/ProductsTop"),
    // ProductsSlider: () => import("../components/ProductsSlider"),
    // PartnersTop: () => import("../components/PartnersTop"),
    // PartnersSlider: () => import("../components/PartnersSlider"),
    // CallToAction: () => import("../components/CallToAction"),
    // Footer: () => import("../components/Footer")
    Header,
    Multidisciplinar,
    ProductsTop,
    ProductsSlider,
    PartnersTop,
    PartnersSlider,
    CallToAction,
    Footer
  }
}
</script>

<style lang="sass"></style>
