<template lang="pug">
  v-app-bar(app elevate-on-scroll :height="$vuetify.breakpoint.xsOnly ? 56 : 80" style="z-index: 999;")
    v-app-bar-nav-icon(v-show="$vuetify.breakpoint.smAndDown" @click="drawer = !drawer" aria-label="Open drawer")
      v-img(v-if="drawer" src="../assets/menu-back-icon.svg" width="24" height="24" contain)
      v-img(v-else src="../assets/menu-icon.svg" width="24" height="24" contain)
    v-container.pa-0(fluid)
      v-row(align="center" justify="start" style="height: 100%;" no-gutters)
        v-col(cols="auto" style="height: 100%;")
          v-btn.px-2.ml-2.ml-sm-8.ml-md-16(aria-label="Ir ao início" text x-large @click="goToHome")
            v-img.mb-n1(src="../assets/logo.svg" :max-width="$vuetify.breakpoint.xsOnly ? 105 : 128" :max-height="$vuetify.breakpoint.xsOnly ? 18 : 22")
        v-col.ml-16(v-show="$vuetify.breakpoint.mdAndUp" style="height: 100%;")
          v-btn.text-lowercase(text x-large color="navBtnText" @click="$emit('goTo', '#servicos')") serviços
          v-btn.text-lowercase(text x-large color="navBtnText" @click="$emit('goTo', '#products-top')") produtos
          //- v-btn.text-lowercase(text x-large color="navBtnText" to="/#") a empresa
          v-btn#contact-btn.text-lowercase(text x-large color="navBtnText" to="/contact") contato
</template>

<script>
import { mapFields } from "vuex-map-fields"
export default {
  computed: {
    ...mapFields("page", ["drawer"])
  },
  methods: {
    goToHome() {
      this.$router.push("/")
    }
  }
}
</script>

<style lang="scss"></style>
