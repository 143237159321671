<template lang="pug">
  div#call-to-action
    v-container(style="max-width: 1366px; position: relative;")
      v-img.desenho4(v-if="$vuetify.breakpoint.smAndUp" src="../assets/desenho4.svg" position="right" contain eager)
      v-row(align="center" :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'start'")
        v-col.px-6(cols="auto")
          v-img.mb-12(src="../assets/logo.svg" max-width="176" max-height="32")
          div.beyond-title Vá <strong><i>além</i></strong> da mudança.
          div.beyond-subtitle.mt-2 Transforme seu negócio.
          v-btn.mt-12.px-12.text-none(@click="$router.push('/contact')" :large="$vuetify.breakpoint.smAndDown" :x-large="$vuetify.breakpoint.mdAndUp" rounded depressed color="beyondTitle" dark) Iniciar meu projeto!
</template>

<script>
export default {
  name: "CallToAction"
}
</script>

<style lang="sass" scoped>
#call-to-action
  position: relative
  background-color: var(--v-beyondWhiteBg-base)
  padding-top: 60px
  padding-bottom: 60px

  @media (min-width: 600px)
    padding-top: 60px
    padding-bottom: 180px

  @media (min-width: 800px)
    padding-bottom: 220px

  @media (min-width: 960px)
    padding-bottom: 250px

  @media (min-width: 1100px)
    padding-bottom: 300px

  @media (min-width: 1325px)
    padding-top: 160px
    padding-bottom: 160px

.beyond-title
  font-size: 48px

  @media (min-width: 600px)
    font-size: 64px

.beyond-subtitle
  font-size: 26px
  font-weight: 400
  color: var(--v-beyondTitle-base)

  @media (min-width: 600px)
    font-size: 40px

.desenho4
  position: absolute
  right: 0
  top: 75%
  width: 50%
  max-width: 774px
  transition: transform .3s cubic-bezier(0.4, 0.0, 0.2, 1)

  &:hover
    transform: scale(1.1)

  @media (min-width: 800px)
    top: 70%

  @media (min-width: 960px)
    top: 180px

  @media (min-width: 1325px)
    top: 50%
    transform: translate(0, -50%)

    &:hover
      transform: translate(0, -50%) scale(1.1)
</style>
