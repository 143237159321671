<template lang="pug">
  div#products-slider.bg4
    v-container.px-md-12(style="max-width: 1366px;")
      v-row(align="end" no-gutters)
        v-col(cols="auto")
          carousel(:autoplay="false" :autoplayHoverPause="true" :perPage="1" :navigationEnabled="$vuetify.breakpoint.mdAndUp" navigationNextLabel="" navigationPrevLabel="" :perPageCustom="[[700, 2], [1200, 3]]" paginationColor="#C4C4C4" paginationActiveColor="#A64BAA")
            slide(v-for="(item, i) in items" :key="i")
              div.mx-4.pb-2.slide-item
                v-card.d-flex.flex-column.shadow.pa-6.box-shadow(height="100%" flat)
                  div
                    v-img.mb-6(:src="item.image" width="100" contain)
                  div
                    .mb-6.beyond-title {{ item.title }}
                  div.d-flex.flex-column.justify-space-between(style="flex-grow: 1;")
                    .beyond-text.mb-12 {{ item.description }}
                    div
                      v-btn.my-3.px-6.text-uppercase.violet-btn(@click="open(item.url)" :small="$vuetify.breakpoint.xsOnly" outlined) Saiba mais
                      
</template>

<script>
import LegisLogo from "../assets/logo-legis.svg"
import AbsensLogo from "../assets/logo-absens.svg"
import QuotiLogo from "../assets/logo-quoti.png"
import ErudioLogo from "../assets/logo-erudio.svg"

export default {
  name: "ProductsSlider",
  data() {
    return {
      items: [
        {
          url: "https://legishub.com.br/",
          title: "Legis",
          description:
            "O Legis é um hub de automações jurídicas que com a tecnologia de RPA reduz o tempo gasto de escritórios de advocacia com atividades manuais.",
          image: LegisLogo
        },
        {
          url: "https://beyondcompany.com.br/",
          title: "Absens",
          description:
            "É um produto desenvolvido juntamente com a prefeitura do recife para reduzir a taxa de absenteísmo no SUS.",
          image: AbsensLogo
        },
        {
          url: "https://erudio.app/",
          title: "Erudio",
          description:
            "O erudio é um produto whitelabel que apresenta componentes necessários para criação de um e-commerce de vídeo aulas.",
          image: ErudioLogo
        },
        {
          url: "https://quoti.com.br",
          title: "Quoti",
          description:
            "É uma plataforma educacional desenvolvida para integrar os serviços que uma instituição de ensino precisa e entregar um acesso único para seus alunos.",
          image: QuotiLogo
        }
      ]
    }
  },
  methods: {
    open(url) {
      window.open(url, "target_blank")
    }
  }
}
</script>

<style lang="sass" scoped>

.box-shadow
  border-radius: 1rem
  background: #FFF
  box-shadow: 0px 4px 5px 0px rgba(13, 3, 27, 0.25) !important

.violet-btn
  color: #401580 !important
  border-color: #401580 !important
  border-radius: 6.25rem
  &:hover
    color: #FFF !important
    background-color: #401580 !important

.bg4
  z-index: 18
  position: relative
  background: url(../assets/bg4.svg)
  background-repeat: no-repeat
  background-size: 100% 100%
  margin-top: -50px
  padding-top: 80px
  padding-bottom: 100px

.slide-item
  padding-top: 60px
  height: 100%

.card
  z-index: 18
  position: relative
  background: #F8F8F8
  border: 1.5px solid #E5E5E5 !important
  box-sizing: border-box
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15) !important

  .v-image
    position: absolute
    width: 90px
    height: 90px
    top: 0
    right: 50%
    transform: translate(50%, -43%)

    @media(min-width: 600px)
      width: 130px
      height: 130px

  .beyond-title
    font-size: 24px

    @media(min-width: 600px)
      font-size: 36px

  .beyond-text
    max-width: 320px
</style>
