<template lang="pug">
  div#multidisciplinar-section.bg2
    v-container(:fluid="$vuetify.breakpoint.mdAndDown" :class="{ 'pa-0': $vuetify.breakpoint.mdAndDown }" style="max-width: 1366px;")
      v-row#servicos.justify-center.justify-sm-end(align="center" no-gutters)
        v-img.desenho2(v-if="$vuetify.breakpoint.smAndUp" src="../assets/desenho2.svg" contain eager)
        v-col(cols="auto")
          v-card.pa-8.pa-sm-10.pa-md-16(flat tile)
            p.beyond-title Multidisciplinar
            p.beyond-text
              span Contando com desenvolvedores, designers e até juristas, nosso time multidisciplinar oferece serviços como 
              span.font-italic.font-weight-medium Inteligência Artificial, Migração e Personalização do G Suite, Arquitetura de Cloud e Desenvolvimento
              span , avaliando grandes volumes de dados e obtendo grandes performances nos negócios dos nossos clientes.
            //- v-btn.mt-4.text-lowercase(:small="$vuetify.breakpoint.xsOnly" rounded depressed color="beyondPurpleBtn" dark) saiba mais
</template>

<script>
export default {
  name: "Header",
  components: {}
}
</script>

<style lang="sass" scoped>
#multidisciplinar-section
  margin-top: -100px
  padding-bottom: 60px

  .row
    position: relative
    padding-top: 180px
    padding-bottom: 30px

    .v-card
      max-width: 302px

      @media (max-width: 600px)
        .beyond-title
          font-size: 24px

      @media (min-width: 600px)
        max-width: 470px

      @media (min-width: 960px)
        max-width: 670px


  .beyond-title
    max-width: 250px

    @media (min-width: 600px)
      max-width: 585px

  .beyond-text
    max-width: 498px

.bg2
  z-index: 20
  position: relative
  background: url(../assets/bg2.svg)
  background-repeat: no-repeat
  background-size: 100% 100%

.desenho2
  position: absolute
  width: 30%
  max-width: 391px
  left: 25%
  transition: transform .3s cubic-bezier(0.4, 0.0, 0.2, 1)

  transform: translate(-50%, 0)

  &:hover
    transform: translate(-50%, 0) scale(1.1)

  @media (min-width: 600px)
    display: none
    left: 25%

  @media (min-width: 700px)
    display: flex
    left: 18%
    max-width: 250px

  @media (min-width: 800px)
    left: 20%
    max-width: 391px

  @media (min-width: 960px)
    left: 20%

  @media (min-width: 1080px)
    left: 25%
</style>
