<template lang="pug">
  div#partners-slider.bg6
    v-container(fluid fill-height)
      v-row(justify="center")
        v-col()
          carousel.my-4.mx-md-12(:autoplay="false" :autoplayHoverPause="true" :perPage="1" :perPageCustom="[[700, 2], [960, 3], [1300, 4], [1580, 5]]" :navigationEnabled="false" navigationNextLabel="" navigationPrevLabel="" paginationColor="#401580" paginationActiveColor="#F8B73F")
            slide(v-for="(item, i) in items" :key="i")
              v-container.d-flex.align-center.justify-center(fill-height)
                v-img(:src="item" max-width="260" max-height="150" contain eager)
</template>

<script>
export default {
  name: "PartnersSlider",
  data() {
    return {
      items: [
        require("../assets/parceiros/eds.png"),
        require("../assets/parceiros/martorelli.png"),
        require("../assets/parceiros/beachpark.png"),
        require("../assets/parceiros/queirozcavalcanti.png"),
        require("../assets/parceiros/trigueirofontes.png")
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
#partners-slider
  // height: 200px
  // margin-bottom: -200px

.bg6
  z-index: 20
  position: relative
  // width: 100%
  // height: 100%
  background-repeat: no-repeat
  background-position: center center
  background-color: var(--v-beyondPurple-base)
  background-blend-mode: screen
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1)
</style>
