import { getField, updateField } from "vuex-map-fields"
import { mail } from "../../../firebase"

export const state = () => ({
  drawer: false
})

export const getters = {
  getField
}

export const actions = {
  async sendForm(_, payload) {
    return mail.add(payload)
  }
}

export const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
