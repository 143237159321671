<template lang="pug">
  div#products-top.bg3
    v-container(style="max-width: 1366px;" fill-height)
      v-row(align="end" style="height: 100%;")
        v-col.px-6.px-sm-8.px-md-16.mb-8
          v-row(align="center" justify="space-between")
            v-col(cols="auto")
              span.beyond-title Nossos produtos
            //- TODO: create portfolio
            //- v-col(cols="auto")
              v-btn.text-none(:large="$vuetify.breakpoint.smAndDown" :x-large="$vuetify.breakpoint.mdAndUp" rounded depressed color="beyondTitle" dark) {{ $vuetify.breakpoint.mdAndUp ? "Clique e veja o portifólio completo" : "Veja o portifólio completo" }}
</template>

<script>
export default {
  name: "ProductsTop"
}
</script>

<style lang="sass" scoped>
#products-top
  height: 280px
  margin-top: -60px
  padding-top: 60px
  // background: var(--v-beyondGreen-base)
  // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1)

.bg3
  z-index: 19
  position: relative
  background: url(../assets/bg3.svg)
  background-repeat: no-repeat
  background-size: 100% 100%
</style>
