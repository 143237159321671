<template lang="pug">
  v-navigation-drawer(app :bottom="$vuetify.breakpoint.xsOnly" v-model="drawer" style="z-index: 999;" clipped hide-overlay disable-resize-watcher)
    v-list-item(to="/")
      v-list-item-content
        v-list-item-title
          v-img(src="../assets/logo.svg" width="105" height="18")
    v-divider
    v-list(nav)
      v-list-item(v-for="item of items" :key="item.title" color="primary" @click="item.action")
        v-list-item-content
          v-list-item-title {{ item.title }}
</template>

<script>
import { mapFields } from "vuex-map-fields"
export default {
  data() {
    return {
      items: [
        {
          title: "serviços",
          action: () => this.$emit("goTo", "#servicos")
        },
        {
          title: "produtos",
          action: () => this.$emit("goTo", "#products-top")
        },
        {
          title: "a empresa",
          action: () => this.$router.push("/contact")
        },
        {
          title: "contato",
          action: () => this.$router.push("/contact")
        }
      ]
    }
  },
  computed: {
    ...mapFields("page", ["drawer"])
  }
}
</script>

<style lang="sass"></style>
